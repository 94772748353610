/**
 * @generated SignedSource<<b77a6274f5bc84c91f43827288c633d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type magazinesStyleSpotlightContentItemContent$data = {
  readonly sections?: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "magazinesStyleSpotlightContentItemContent";
};
export type magazinesStyleSpotlightContentItemContent$key = {
  readonly " $data"?: magazinesStyleSpotlightContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleSpotlightContentItemContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "magazinesStyleSpotlightContentItemContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SectionSegment",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Section",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize800x1200",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "83148e3c3ebfcd4e5a70f5eb32bbf092";

export default node;
