import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";

import { LatestDiscoveryWidget } from "scmp-app/components/rhs-module/latest-discovery-widget";
import type { asiaDiscoveryWidgetQuery$key } from "scmp-app/queries/__generated__/asiaDiscoveryWidgetQuery.graphql";

type Props = {
  className?: string;
  reference: asiaDiscoveryWidgetQuery$key;
};

export const AsiaDiscoveryWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment asiaDiscoveryWidgetQuery on Query {
        asiaSection: section(filter: { entityId: "3" }) {
          ...latestDiscoveryWidgetSection
        }
        asiaQueue: queue(filter: { name: "section_top_3" }) {
          ...latestDiscoveryWidgetQueue
        }
      }
    `,
    reference,
  );
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.AsiaLatest,
      }}
    >
      <LatestDiscoveryWidget
        className={className}
        queueReference={data.asiaQueue}
        sectionReference={data.asiaSection}
      />
    </BaseLinkContextProvider>
  );
};

AsiaDiscoveryWidget.displayName = "AsiaDiscoveryWidget";
