/**
 * @generated SignedSource<<92ccc930d5217823f60142d07c9198c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionPostMagazineQuery$data = {
  readonly leadStoryPostMagQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"leadStoryPostMagazineContent">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"onePostMagSectionTopWidgetArticles" | "threePostMagSectionTopWidgetArticles" | "twoPostMagSectionTopWidgetArticles">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly sponsorQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"onePostMagSectionTopWidgetSponsorQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"headerPostMagazineQuery" | "postMagazineNewsletter">;
  readonly " $fragmentType": "sectionPostMagazineQuery";
};
export type sectionPostMagazineQuery$key = {
  readonly " $data"?: sectionPostMagazineQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionPostMagazineQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 6
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sponsorQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionPostMagazineQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerPostMagazineQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "postMagazineNewsletter"
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "version",
              "value": 2
            }
          ],
          "kind": "ScalarField",
          "name": "advertZone",
          "storageKey": "advertZone(version:2)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "leadStoryPostMagQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "onePostMagSectionTopWidgetArticles"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "twoPostMagSectionTopWidgetArticles"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "threePostMagSectionTopWidgetArticles"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "leadStoryPostMagazineContent"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:6)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "sponsorQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "sponsorQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "onePostMagSectionTopWidgetSponsorQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:6)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "40fb0cfe5e33d25b85a36b089075adb4";

export default node;
