/**
 * @generated SignedSource<<ef9c201982e585fc9e5f6c5bd3a83a19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleMainQuery$data = {
  readonly beautyArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly beautySection: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListSection">;
  };
  readonly fashionArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly fashionSection: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListSection">;
  };
  readonly lifestyleArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly lifestyleSection: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListSection">;
  };
  readonly luxuryArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly luxurySection: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListSection">;
  };
  readonly peopleArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly peopleSection: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListSection">;
  };
  readonly topArticleQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleFashionCarouselQuery" | "sectionStyleSpotlightQuery" | "sectionStyleVideoWidgetQuery">;
  readonly " $fragmentType": "sectionStyleMainQuery";
};
export type sectionStyleMainQuery$key = {
  readonly " $data"?: sectionStyleMainQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 3
      }
    ],
    "kind": "FragmentSpread",
    "name": "sectionStyleMainArticleListQueue"
  }
],
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "sectionStyleMainArticleListSection"
  }
],
v2 = [
  {
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 4
      }
    ],
    "kind": "FragmentSpread",
    "name": "sectionStyleMainArticleListQueue"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleMainQuery",
  "selections": [
    {
      "alias": "topArticleQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "luxurySection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "322897"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"322897\"})"
    },
    {
      "alias": "luxuryArticleQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_322897"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_322897\"})"
    },
    {
      "alias": "fashionSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "516294"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"516294\"})"
    },
    {
      "alias": "fashionArticleQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_516294"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_516294\"})"
    },
    {
      "alias": "beautySection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "516298"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"516298\"})"
    },
    {
      "alias": "beautyArticleQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_516298"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_516298\"})"
    },
    {
      "alias": "peopleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "516301"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"516301\"})"
    },
    {
      "alias": "peopleArticleQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_516301"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_516301\"})"
    },
    {
      "alias": "lifestyleSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "516305"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "section(filter:{\"entityId\":\"516305\"})"
    },
    {
      "alias": "lifestyleArticleQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_516305"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "queue(filter:{\"name\":\"section_top_516305\"})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sectionStyleFashionCarouselQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sectionStyleSpotlightQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sectionStyleVideoWidgetQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "75ef54d48ce93d34c0251101b90962bf";

export default node;
