/**
 * @generated SignedSource<<2e3e01b2d562adfa46c47dd07a97ebe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type asiaDiscoveryWidgetQuery$data = {
  readonly asiaQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetQueue">;
  } | null | undefined;
  readonly asiaSection: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetSection">;
  };
  readonly " $fragmentType": "asiaDiscoveryWidgetQuery";
};
export type asiaDiscoveryWidgetQuery$key = {
  readonly " $data"?: asiaDiscoveryWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"asiaDiscoveryWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "asiaDiscoveryWidgetQuery",
  "selections": [
    {
      "alias": "asiaSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "3"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetSection"
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"3\"})"
    },
    {
      "alias": "asiaQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_3"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetQueue"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_top_3\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "65769c59605641c57aae685317f8790e";

export default node;
