import styled from "@emotion/styled";
import { fontBarlow, fontBarlowCondensed, theme } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

export const Topic = styled.div`
  display: -webkit-box;

  margin-block-end: 16px;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  > a {
    display: inline;

    color: #000000;
    font-weight: 400;
    font-size: 20px;
    font-family: ${fontBarlowCondensed};
    line-height: 32px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }
`;

export const Cover = styled.div`
  position: relative;

  inline-size: 100%;
  block-size: 100%;

  border-radius: 16px;

  overflow: hidden;

  figure {
    aspect-ratio: 300 / 536;
  }

  ${theme.breakpoints.up("tablet")} {
    border-radius: 20px;

    figure {
      aspect-ratio: 375 / 667;
    }
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;

  inline-size: 100%;
  block-size: 45%;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  opacity: 0.5;
`;

export const Content = styled.div`
  position: absolute;
  inset-block-end: 0;

  display: flex;
  flex-direction: column;
  justify-content: end;

  inline-size: 100%;
  block-size: 100%;
  padding-block: 32px 32px;
  padding-inline: 32px 32px;
`;

export const Title = styled(EntityLink)`
  display: -webkit-box;

  inline-size: 100%;

  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  font-family: ${fontBarlow};
  line-height: 24px;
  text-align: start;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  /* Fit clickable area to parent */
  &::before {
    position: absolute;
    inset-block: 0 0;
    inset-inline: 0 0;
    content: "";

    display: block;
  }
`;

export const Container = styled.div`
  text-align: start;
`;
