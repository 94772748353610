import first from "lodash/first";
import last from "lodash/last";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { magazinesStyleSpotlightContentItemContent$key } from "scmp-app/queries/__generated__/magazinesStyleSpotlightContentItemContent.graphql";

import { Backdrop, Container, Content, Cover, Divider, ReadMore, Section, Title } from "./styles";

export type Props = ContentItemRenderVariantProps & {
  reference: magazinesStyleSpotlightContentItemContent$key;
};

export const ContentItemMagazinesStyleSpotlight = forwardRef<HTMLDivElement, Props>(
  ({ className, reference: reference_ }, reference) => {
    const data = useFragment(
      graphql`
        fragment magazinesStyleSpotlightContentItemContent on Content {
          ...entityLink
          ... on Article {
            sections {
              value {
                name
              }
            }
          }
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize800x1200: true, withHeadline: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(data);

    const cover = providers.coverImage({
      responsiveVariants: {
        desktopUp: "size800x1200",
        mobileUp: "size800x1200",
        tabletUp: "size800x1200",
      },
    });

    const section = last(first(data.sections)?.value)?.name;

    const title = providers.headline({ hideTooltip: true, preferSocialHeadline: true });

    return (
      <Container className={className} ref={reference}>
        <Cover>
          {cover}
          <Backdrop />
          <Content>
            <Section>{section}</Section>
            <Title reference={data}>{title}</Title>
            <Divider />
            <ReadMore>read more</ReadMore>
          </Content>
        </Cover>
      </Container>
    );
  },
);

ContentItemMagazinesStyleSpotlight.displayName = "ContentItemMagazinesStyleSpotlight";
