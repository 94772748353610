/**
 * @generated SignedSource<<ba85ba826d26ed3a75b8ea1d3921933f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionSpotlightQuery$data = {
  readonly sectionSpotlightQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"topStoriesBlockQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionSpotlightQuery";
};
export type sectionSpotlightQuery$key = {
  readonly " $data"?: sectionSpotlightQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionSpotlightQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sectionSpotlightQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionSpotlightQuery",
  "selections": [
    {
      "alias": "sectionSpotlightQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "sectionSpotlightQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesBlockQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9ea2d94d3c40436cbd884e425175f09b";

export default node;
