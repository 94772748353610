/**
 * @generated SignedSource<<c1a88a95eafae2a1abbf7398ed0917cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type thisWeekInAsiaDiscoveryWidgetQuery$data = {
  readonly twiaQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetQueue">;
  } | null | undefined;
  readonly twiaSection: {
    readonly name: string;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetSection" | "sectionTermLogoSection">;
  };
  readonly " $fragmentType": "thisWeekInAsiaDiscoveryWidgetQuery";
};
export type thisWeekInAsiaDiscoveryWidgetQuery$key = {
  readonly " $data"?: thisWeekInAsiaDiscoveryWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"thisWeekInAsiaDiscoveryWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "thisWeekInAsiaDiscoveryWidgetQuery",
  "selections": [
    {
      "alias": "twiaSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "323045"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetSection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "sectionTermLogoSection"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"323045\"})"
    },
    {
      "alias": "twiaQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_323045"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetQueue"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_top_323045\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ee4605423184e77c615f1606686742ae";

export default node;
