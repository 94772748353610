/**
 * @generated SignedSource<<5a054c3b96035de368a99ac46781d5df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleHeaderMenuItem$data = {
  readonly entityId: string;
  readonly name: string;
  readonly " $fragmentType": "sectionStyleHeaderMenuItem";
};
export type sectionStyleHeaderMenuItem$key = {
  readonly " $data"?: sectionStyleHeaderMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleHeaderMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleHeaderMenuItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "8045124c66e0cee36c7e07a1b81cb323";

export default node;
