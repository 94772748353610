import styled from "@emotion/styled";
import { fontPlayfairDisplay, theme } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";

export const SectionNameText = styled.span`
  font-weight: normal;
  font-size: 22px;
  font-family: ${fontPlayfairDisplay};
  text-transform: uppercase;

  ${theme.breakpoints.up("desktop")} {
    font-size: 35px;
  }
`;

export const StyledBaseImage = styled(BaseImage)`
  inline-size: auto;
  block-size: 18px;

  ${theme.breakpoints.up("tablet")} {
    block-size: 26.5px;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: 35px;
  }
`;
