/**
 * @generated SignedSource<<89b0a0b9251c48d180eb24dbb6eddc50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadStoryPostMagazineContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"postMagazineLeadContentItemContent">;
  readonly " $fragmentType": "leadStoryPostMagazineContent";
};
export type leadStoryPostMagazineContent$key = {
  readonly " $data"?: leadStoryPostMagazineContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadStoryPostMagazineContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadStoryPostMagazineContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "postMagazineLeadContentItemContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "84e6aae82a5c790a98e75aaeb42ea9f3";

export default node;
