/* eslint-disable max-lines */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { section as sectionData } from "shared/data/section";
import { setFullViewportWidthStyles } from "shared/lib/styles";

import { Container as AdSlotContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { FirstRow, SecondRow } from "scmp-app/components/comment/styles";
import { StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import {
  ActionBar,
  Container as HomePrimaryContainer,
  Content as HomePrimaryContent,
} from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import { EntityFollowButton } from "scmp-app/components/entity-follow-button";
import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { StyledSwiperSlide } from "scmp-app/components/entity-oneline-menu/styles";
import { HomeFocusArticle } from "scmp-app/components/home/focus-article";
import { Logo as SectionLogo } from "scmp-app/components/home/term-logo/styles";
import { PlusWidget } from "scmp-app/components/plus/plus-widget";
import { Container as PostMagazineDiscoveryContainer } from "scmp-app/components/post-magazine/discovery-widget/styles";
import { Container as SectionDescription } from "scmp-app/components/section/section-description/styles";
import { Primary as SectionTopWidgetOnePrimary } from "scmp-app/components/section/section-top/variant/one/styles";
import { Container as SubsectionMenuContainer } from "scmp-app/components/subsection-menu/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";

import type { HeaderVariant } from "./types";

export const LatestContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const CommentContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;

    ${FirstRow}, ${SecondRow} {
      gap: 28px;
    }
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const TrendingTopicContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const MultimediaContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 32px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const MostPopularContainer = styled.div`
  margin-block-start: 32px;
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 48px;
  }
`;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  ${HomePrimaryContainer} {
    display: flex;
    flex-direction: column-reverse;
  }

  ${HomePrimaryContent} {
    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 20px;
    }
  }

  ${StyledFigcaption} {
    display: none !important;
  }
`;

export const Container = styled.div`
  inline-size: 100%;
  margin-block-start: 20px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${setFullViewportWidthStyles}
`;

export const ContentContainer = styled.div`
  max-inline-size: 1024px;
  margin-inline: auto;
  padding-block-end: 32px;
  padding-inline: 20px;

  ${theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 1272px;
    padding-block-end: 48px;
  }
`;

export const StyledHomeFocusArticle = styled(HomeFocusArticle)`
  margin-block: 32px;
  padding-block-start: 12px;
  border-block-start: 1px solid #000000;

  ${theme.breakpoints.up("tablet")} {
    margin-block: 32px 0;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block: 0;
  }
`;

const getGridLayoutBySection = (sectionId: string) => {
  switch (sectionId) {
    case sectionData.asia.entityId:
    case sectionData.thisWeekInAsia.entityId:
    case sectionData.world.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 first-ads" min-content
          "top-stories-2 ." 0
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" minmax(min-content, 1fr)
          "subsection-3 rhs-module-4" 0
          "subsection-3 ." 32px
          "subsection-3 rhs-module-5" min-content
          "subsection-3 ." 32px
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-4 ." 0
          "subsection-4 ." min-content
          "subsection-5 rhs-module-6" min-content
          "subsection-5 fifth-ads" min-content
          "subsection-5 ." min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 ." 48px
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.business.entityId:
    case sectionData.china.entityId:
    case sectionData.economy.entityId:
    case sectionData.hongKong.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" 1fr
          "in-house-ads-newsletter-1 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-3 rhs-module-5" min-content
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-5 rhs-module-6" min-content
          "subsection-5 fifth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.chinaFutureTech.entityId:
      return css`
        grid:
          "header header" min-content
          "section-description first-ads" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "hero-articles ." min-content
          "top-stories-1 rhs-module-2" min-content
          "top-stories-2 rhs-module-2" min-content
          "top-stories-2 second-ads" 1fr
          "top-stories-3 second-ads" min-content
          "top-stories-3 ." min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "spotlight third-ads" min-content
          "newsletter-widget third-ads" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." min-content
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" minmax(min-content, 1fr)
          "subsection-3 ." 32px
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-5 ." 48px
          "subsection-5 rhs-module-5" min-content
          "in-house-ads-newsletter-2 rhs-module-5" min-content
          "subsection-6 rhs-module-5" min-content
          "subsection-6 ." 32px
          "subsection-6 rhs-module-6" min-content
          "subsection-6 ." 32px
          "subsection-6 fifth-ads" min-content
          "subsection-rest fifth-ads" min-content
          "subsection-rest sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${FifthAds} {
          max-block-size: 600px;
          margin-block-start: 0;
        }
      `;
    case sectionData.comment.entityId:
    case sectionData.kPop.entityId:
    case sectionData.sport.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 first-ads" min-content
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 rhs-module-4" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 ." min-content
          "subsection-3 ." 48px
          "subsection-3 fourth-ads" min-content
          "subsection-3 ." min-content
          "subsection-4 ." 16px
          "subsection-4 fifth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 rhs-module-5" min-content
          "subsection-5 sixth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.lifestyle.entityId:
    case sectionData.news.peopleAndCulture.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 ." 32px
          "top-stories-2 first-ads" min-content
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "top-stories-4 ." 32px
          "top-stories-4 second-ads" min-content
          "in-house-ads-newsletter-1 second-ads" min-content
          ". ." 48px
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-3 ." 32px
          "subsection-3 rhs-module-5" min-content
          "subsection-3 ." 32px
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" 1fr
          "subsection-4 ." min-content
          "subsection-5 fifth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${FifthAds}, ${RHSModule4} {
          margin-block-start: 48px;
        }

        ${RHSModule6} {
          display: none;
        }

        ${FirstAds} {
          margin-block-start: 0;
        }

        ${InHouseAdsAndNewsletter1} {
          margin-block: 48px 0;
        }
      `;
    case sectionData.olympic.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 ." min-content
          "top-stories-2 ." min-content
          "top-stories-3 ." min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-1" min-content
          "newsletter-widget rhs-module-1" min-content
          "subsection-1 rhs-module-1" min-content
          "subsection-1 ." 32px
          "subsection-1 second-ads" min-content
          "subsection-1 ." min-content
          "subsection-2 rhs-module-2" min-content
          "subsection-3 rhs-module-2" min-content
          "subsection-3 ." 32px
          "subsection-3 third-ads" min-content
          "subsection-3 ." min-content
          "subsection-4 ." min-content
          "subsection-4 ." 32px
          "subsection-5 rhs-module-3" min-content
          "subsection-5 ." 32px
          "subsection-5 fourth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 ." 16px
          "subsection-6 fifth-ads" min-content
          "subsection-6 rhs-module-4" min-content
          "subsection-6 rhs-module-5" min-content
          "subsection-6 sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule1}, ${SecondAds}, ${ThirdAds} {
          margin-block-start: 0;
        }

        ${RHSModule3} {
          > div {
            margin-block-start: 32px;
          }
        }

        ${Subsection2}, ${Subsection3}, ${Subsection4}, ${Subsection5} {
          margin-block-start: 32px;
        }
      `;
    case sectionData.tech.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-4 rhs-module-2" min-content
          "in-house-ads-newsletter-1 second-ads" min-content
          "spotlight second-ads" min-content
          "spotlight ." 48px
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." min-content
          "subsection-1 ." 0
          "subsection-2 ." 48px
          "subsection-2 rhs-module-4" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-3 ." 0
          "subsection-3 rhs-module-5" min-content
          "subsection-3 ." 32px
          "subsection-3 fourth-ads" min-content
          "subsection-4 fourth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 ." 16px
          "subsection-5 fifth-ads" min-content
          "subsection-5 ." min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 ." 32px
          "subsection-rest sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
    case sectionData.us.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "hero-articles ." min-content
          "top-stories-1 ." min-content
          "top-stories-2 rhs-module-2" min-content
          "top-stories-2 second-ads" min-content
          "top-stories-3 second-ads" min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 third-ads" min-content
          "subsection-1 ." min-content
          "subsection-2 ." min-content
          "subsection-3 fourth-ads" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-4 rhs-module-4" min-content
          "subsection-5 rhs-module-5" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 fifth-ads" min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${FourthAds}, ${FifthAds}, ${RHSModule5} {
          margin-block-start: 48px;
        }
        ${RHSModule4} {
          margin-block-start: 32px;
        }
        ${ThirdAds} {
          margin-block-start: 0;
        }
      `;
    case sectionData.us.usElections.entityId:
      return css`
        grid:
          "header header" min-content
          "section-description first-ads" min-content
          "hero-articles first-ads" min-content
          "hero-articles rhs-module-1" min-content
          "hero-articles ." min-content
          "top-stories-1 ." min-content
          "top-stories-2 rhs-module-2" min-content
          "top-stories-2 second-ads" min-content
          "top-stories-3 second-ads" min-content
          "top-stories-4 ." min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 third-ads" min-content
          "subsection-1 ." min-content
          "subsection-2 ." min-content
          "subsection-3 fourth-ads" min-content
          "subsection-3 rhs-module-4" min-content
          "subsection-4 rhs-module-4" min-content
          "subsection-5 rhs-module-5" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 fifth-ads" min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest sixth-ads" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${FourthAds}, ${FifthAds}, ${RHSModule5} {
          margin-block-start: 48px;
        }
        ${RHSModule4} {
          margin-block-start: 32px;
        }
        ${ThirdAds} {
          margin-block-start: 0;
        }
      `;

    default:
      return css`
        grid:
          "header header" min-content
          "hero-articles rhs-module-1" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-1 first-ads" min-content
          "top-stories-2 rhs-module-2" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 second-ads" 1fr
          "top-stories-4 second-ads" min-content
          "in-house-ads-newsletter-1 ." min-content
          "spotlight rhs-module-3" min-content
          "newsletter-widget rhs-module-3" min-content
          "subsection-1 rhs-module-3" min-content
          "subsection-1 ." 32px
          "subsection-1 rhs-module-4" min-content
          "subsection-1 third-ads" min-content
          "subsection-1 ." 0
          "subsection-1 ." min-content
          "subsection-2 rhs-module-5" min-content
          "subsection-2 fourth-ads" min-content
          "subsection-2 ." min-content
          "subsection-3 ." 16px
          "subsection-3 ." min-content
          "subsection-4 ." 16px
          "subsection-4 fifth-ads" min-content
          "subsection-4 ." min-content
          "subsection-5 sixth-ads" min-content
          "in-house-ads-newsletter-2 ." min-content
          "subsection-6 rhs-module-6" min-content
          "subsection-rest ." min-content
          / minmax(0, 1fr) minmax(0, 300px);
      `;
  }
};

type ContentWrapperProps = {
  $sectionId?: string;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  grid:
    "header" min-content
    "section-description" min-content
    "hero-articles" min-content
    "first-ads" min-content
    "top-stories-1" min-content
    "rhs-module-1" min-content
    "second-ads" min-content
    "top-stories-2" min-content
    "rhs-module-2" min-content
    "third-ads" min-content
    "top-stories-3" min-content
    "top-stories-4" min-content
    "in-house-ads-newsletter-1" min-content
    "spotlight" min-content
    "newsletter-widget" min-content
    "rhs-module-3" min-content
    "fourth-ads" min-content
    "subsection-1" min-content
    "subsection-2" min-content
    "rhs-module-4" min-content
    "fifth-ads" min-content
    "subsection-3" min-content
    "subsection-4" min-content
    "in-house-ads-newsletter-2" min-content
    "rhs-module-5" min-content
    "sixth-ads" min-content
    "subsection-5" min-content
    "rhs-module-6" min-content
    "subsection-6" min-content
    "subsection-rest" min-content
    / minmax(0, 1fr);

  ${PostMagazineDiscoveryContainer} {
    margin-block-start: 20px;

    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 32px;
    }
    ${theme.breakpoints.up("desktop")} {
      margin-block-start: 0;
    }
  }

  ${theme.breakpoints.up("desktop")} {
    column-gap: 40px;

    ${props => getGridLayoutBySection(props.$sectionId ?? "")};
  }

  time {
    line-height: 14px;
  }
`;

export const StyledEntityOnelineMenu = styled(EntityOnelineMenu)`
  ${StyledSwiperSlide} {
    &.swiper-slide {
      &:not(:last-child) {
        margin-inline-end: 16px;
      }
    }
  }
`;

type HeaderProps = {
  $variant?: HeaderVariant;
  $withSectionLogo?: boolean;
};

export const OneLineMenuLabel = styled.span`
  &:not(:empty) {
    margin-inline-end: 20px;

    font-weight: 700;
    line-height: 140%;
  }
`;
export const OneLineMenuContainer = styled.div`
  grid-area: menu;

  display: flex;
  align-items: center;
`;

export const Header = styled.div<HeaderProps>`
  grid-area: header;

  border-block-end: 1px rgba(0, 0, 0, 0.1) solid;

  display: grid;
  grid:
    "section-logo page-header" min-content
    "menu menu" min-content
    / min-content 1fr;

  padding-block-end: 16px;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "section-logo page-header" min-content
      "section-logo menu" min-content
      / min-content 1fr;

    padding-block-end: 24px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    font-family: ${fontRobotoCondensed};
    line-height: 120%;
    text-transform: uppercase;

    ${theme.breakpoints.up("tablet")} {
      font-size: 32px;
    }

    ${theme.breakpoints.up("desktop")} {
      font-size: 44px;
    }
  }

  ${SectionLogo} {
    grid-area: section-logo;

    inline-size: 60px;
    block-size: 60px;
    margin-inline-end: 20px;

    ${theme.breakpoints.up("tablet")} {
      inline-size: 80px;
      block-size: 80px;
    }
  }

  ${OneLineMenuContainer} {
    margin-block-start: ${props => (props.$withSectionLogo ? "8px" : "16px")};

    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 8px;
    }
  }

  ${StyledEntityOnelineMenu} {
    flex: 1;

    margin-block-start: 0;

    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 0;
    }
  }

  ${props =>
    props.$variant === "twia" &&
    css`
      ${SectionNameContainer} {
        justify-content: center;
      }

      ${StyledEntityOnelineMenu} {
        display: flex;
        justify-content: center;
      }
    `};
`;

export const HeroArticles = styled.div`
  grid-area: hero-articles;

  margin-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${SectionTopWidgetOnePrimary} {
    margin-block-end: 32px;
    padding-block-end: 32px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);

    ${theme.breakpoints.up("desktop")} {
      margin-block-end: 0;
      padding-block-end: 0;
      border-block-end: none;
    }
  }
`;

export const TopStories = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
  }

  ${ActionBar} {
    margin-block-end: 0;
  }
`;

export const TopStories1 = styled(TopStories)`
  grid-area: top-stories-1;

  margin-block-start: 32px;
  padding-block-start: 32px;
`;

export const TopStories2 = styled(TopStories)`
  grid-area: top-stories-2;
`;

export const TopStories3 = styled(TopStories)`
  grid-area: top-stories-3;
`;

export const TopStories4 = styled(TopStories)`
  grid-area: top-stories-4;
`;

export const InHouseAdsAndNewsletter1 = styled.div`
  grid-area: in-house-ads-newsletter-1;

  margin-block: 10px 46px;

  ${theme.breakpoints.up("tablet")} {
    margin-block: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block: 48px;
  }
`;

export const InHouseAdsAndNewsletter2 = styled.div`
  grid-area: in-house-ads-newsletter-2;

  margin-block: 30px 46px;

  ${theme.breakpoints.up("tablet")} {
    margin-block: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block: 48px 0;
  }
`;

export const Spotlight = styled.div`
  grid-area: spotlight;

  padding-block-start: 16px;
  border-block-start: 1px #000000 solid;
`;

export const Newsletter = styled.div`
  grid-area: newsletter-widget;

  margin-block-start: 32px;

  background-color: rgba(0, 0, 0, 0.04);

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 24px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 48px;
  }
`;

const Subsection = styled.div`
  margin-block-start: 32px;

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 48px;
  }

  ${SubsectionMenuContainer} {
    grid:
      "section-name-follow" min-content
      / 100%;
  }
`;

export const Subsection1 = styled(Subsection)`
  grid-area: subsection-1;
`;

export const Subsection2 = styled(Subsection)`
  grid-area: subsection-2;
`;

export const Subsection3 = styled(Subsection)`
  grid-area: subsection-3;
`;

export const Subsection4 = styled(Subsection)`
  grid-area: subsection-4;
`;

export const Subsection5 = styled(Subsection)`
  grid-area: subsection-5;
`;

export const Subsection6 = styled(Subsection)`
  grid-area: subsection-6;
`;

export const SubsectionRest = styled.div`
  grid-area: subsection-rest;

  display: flex;
  flex-flow: column nowrap;

  ${SubsectionMenuContainer} {
    margin-block-start: 0;
  }

  ${SubsectionMenuContainer} {
    grid:
      "section-name-follow" min-content
      / 100%;
  }
`;

export const RHSModule1 = styled.div`
  grid-area: rhs-module-1;
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
  }
`;

export const RHSModule2 = styled.div`
  grid-area: rhs-module-2;
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
  }
`;

export const RHSModule3 = styled.div`
  grid-area: rhs-module-3;
`;

export const RHSModule4 = styled.div`
  grid-area: rhs-module-4;
`;

export const RHSModule5 = styled.div`
  grid-area: rhs-module-5;
`;

export const RHSModule6 = styled.div`
  grid-area: rhs-module-6;
`;

export const FirstAds = styled.div`
  grid-area: first-ads;

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const SecondAds = styled.div`
  grid-area: second-ads;

  margin-block-start: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const ThirdAds = styled.div`
  grid-area: third-ads;

  margin-block-start: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    ${AdSlotContainer} {
      block-size: auto;
    }
  }
`;

export const FourthAds = styled.div`
  grid-area: fourth-ads;

  margin-block-start: 32px;

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }

  ${AdSlotContainer} {
    block-size: auto;
  }
`;

export const FifthAds = styled.div`
  grid-area: fifth-ads;

  margin-block-start: 32px;
`;

export const SixthAds = styled.div`
  grid-area: sixth-ads;

  margin-block-start: 32px;

  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const StyledPlusWidget = styled(PlusWidget)`
  margin-block-start: 32px;
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const SectionDescriptionContainer = styled.div`
  grid-area: section-description;

  display: none;
  &:not(:empty) {
    display: block;
    ${SectionDescription} {
      inline-size: 100%;
      max-inline-size: 100%;
      margin-block-start: 16px;

      ${theme.breakpoints.up("tablet")} {
        max-inline-size: 100%;
        margin-block-start: 32px;
      }
    }
  }
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)`
  button {
    inline-size: auto;
    padding-block: 4px 3px;
    padding-inline: 8px;

    font-weight: 400;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    line-height: 19.6px;
    white-space: nowrap;
  }
`;

export const SectionNameContainer = styled.div`
  grid-area: page-header;

  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.up("tablet")} {
    justify-content: flex-start;
  }
`;
