/**
 * @generated SignedSource<<26ca183a48a567678ef7242989105163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleSpotlightQuery$data = {
  readonly spotlight: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleSpotlightContentItemContent">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionStyleSpotlightQuery";
};
export type sectionStyleSpotlightQuery$key = {
  readonly " $data"?: sectionStyleSpotlightQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleSpotlightQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleSpotlightQuery",
  "selections": [
    {
      "alias": "spotlight",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "4335"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "type": "BaseWithApplicationAndUrlAlias",
                      "abstractKey": "__isBaseWithApplicationAndUrlAlias"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "magazinesStyleSpotlightContentItemContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "queue(filter:{\"entityId\":\"4335\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cb4dbd106ef5fc0e037cdd3fa9e95b5c";

export default node;
