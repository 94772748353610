/* eslint-disable relay/unused-fields */
import { type FunctionComponent, type ReactNode } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import type {
  clientQueryLoaderDefaultSectionQuery,
  clientQueryLoaderDefaultSectionQuery$data,
} from "scmp-app/queries/__generated__/clientQueryLoaderDefaultSectionQuery.graphql";

export type Props = {
  children?: (payload: Payload) => ReactNode;
  sectionEntityId: string;
  ssrContentEntityIds: string[];
};

type Payload = {
  data: clientQueryLoaderDefaultSectionQuery$data;
};

export const DefaultSectionClientQueryLoader: FunctionComponent<Props> = ({
  children,
  sectionEntityId,
  ssrContentEntityIds,
}) => {
  const data = useLazyLoadQuery<clientQueryLoaderDefaultSectionQuery>(
    graphql`
      query clientQueryLoaderDefaultSectionQuery(
        $excludeSsrContentEntityIds: [String]!
        $sectionEntityId: String!
      ) {
        section: section(filter: { entityId: $sectionEntityId }) {
          subSections {
            # Harrys view will be in phase 2, exclude it for now
            items(exclude: { entityIds: ["324599"] }) {
              edges {
                node {
                  ... on Section {
                    name
                    topStories {
                      items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                        edges {
                          ...oneSectionTopWidgetQueue
                          ...twoSectionTopWidgetQueue
                        }
                      }
                    }
                    ...subsectionMenuSection
                  }
                }
              }
            }
          }
        }
        ...sectionNewsletterWidgetQuery @arguments(entityId: $sectionEntityId)
      }
    `,
    {
      excludeSsrContentEntityIds: ssrContentEntityIds,
      sectionEntityId,
    },
    { fetchPolicy: "store-or-network" },
  );

  return children?.({
    data,
  });
};

DefaultSectionClientQueryLoader.displayName = "DefaultSectionClientQueryLoader";
