import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { Swiper } from "scmp-app/components/swiper";

import HeaderLogo from "./section-style-spotlight-logo.svg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const SwiperContainer = styled.div`
  inline-size: 100vw;
  max-inline-size: 1180px;
  margin-block-end: 30px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-block: 56px;
  ${theme.breakpoints.up("tablet")} {
    margin-block: 48px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block: 64px;
  }
`;

export const StyledHeaderLogo = styled(HeaderLogo)`
  block-size: 41px;
  ${theme.breakpoints.up("tablet")} {
    block-size: 48px;
  }
  ${theme.breakpoints.up("desktop")} {
    block-size: 64px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block: 0 0;

    .swiper-wrapper {
      block-size: 100%;
      padding-block-end: 40px;
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: #222222;
      }
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  &.swiper-slide {
    inline-size: 300px;

    :first-child {
      inline-size: 316px;

      > * {
        padding-inline-start: 16px;
      }
    }

    :last-child {
      inline-size: 316px;

      > * {
        padding-inline-end: 16px;
      }
    }
  }

  ${theme.breakpoints.up("tablet")} {
    &.swiper-slide {
      inline-size: 384px;

      :first-child {
        inline-size: 384px;

        > * {
          padding-inline-start: 0;
        }
      }

      :last-child {
        inline-size: 384px;

        > * {
          padding-inline-end: 0;
        }
      }
    }
  }
`;
