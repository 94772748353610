import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { tracking } from "shared/data";

import type { thisWeekInAsiaDiscoveryWidgetQuery$key } from "scmp-app/queries/__generated__/thisWeekInAsiaDiscoveryWidgetQuery.graphql";

import { HighlightsFrom, StyledLatestDiscoveryWidget, Title, TitleContainer } from "./styles";

type Props = {
  className?: string;
  reference: thisWeekInAsiaDiscoveryWidgetQuery$key;
};

export const ThisWeekInAsiaDiscoveryWidget: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment thisWeekInAsiaDiscoveryWidgetQuery on Query {
        twiaSection: section(filter: { entityId: "323045" }) {
          ...latestDiscoveryWidgetSection
          ...sectionTermLogoSection
          urlAlias
          name
        }
        twiaQueue: queue(filter: { name: "section_top_323045" }) {
          ...latestDiscoveryWidgetQueue
        }
      }
    `,
    reference,
  );

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TwiaHighlight,
      }}
    >
      <StyledLatestDiscoveryWidget
        className={className}
        overrideMoreLinkLabel={`MORE IN ${data.twiaSection.name}`}
        queueReference={data.twiaQueue}
        sectionReference={data.twiaSection}
        titleComponent={
          <TitleContainer>
            <HighlightsFrom>Highlights from</HighlightsFrom>
            <Title pathname={data?.twiaSection?.urlAlias}>THIS WEEK IN ASIA</Title>
          </TitleContainer>
        }
        variant="image-first"
      />
    </BaseLinkContextProvider>
  );
};

ThisWeekInAsiaDiscoveryWidget.displayName = "ThisWeekInAsiaDiscoveryWidget";
