import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { useQueryLoader } from "react-relay";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import type { sectionStyleMainHeadNavQuery } from "scmp-app/queries/__generated__/sectionStyleMainHeadNavQuery.graphql";

import { query, SectionStyleMainHeadNav } from "./section-style-main-head-nav";
import { Container, LogoContainer, NavContainer, StyledLogo } from "./styles";

export const SectionStyleMainHead: FunctionComponent = () => {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<sectionStyleMainHeadNavQuery>(query);

  useEffect(() => {
    loadQuery({});
    return disposeQuery;
  }, [loadQuery, disposeQuery]);

  return (
    <Container>
      <LogoContainer>
        <StyledLogo />
      </LogoContainer>
      <NavContainer>
        <ClientSideSuspense>
          {queryReference && <SectionStyleMainHeadNav queryReference={queryReference} />}
        </ClientSideSuspense>
      </NavContainer>
    </Container>
  );
};

SectionStyleMainHead.displayName = "SectionStyleMainHead";
