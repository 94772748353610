/**
 * @generated SignedSource<<3f70873778b43029ceebc8b0c442bd86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleMainArticleListQueue$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"magazinesStyleCardContentItemContent" | "magazinesStyleLeadContentItemContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "sectionStyleMainArticleListQueue";
};
export type sectionStyleMainArticleListQueue$key = {
  readonly " $data"?: sectionStyleMainArticleListQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainArticleListQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 4,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "items"
        ]
      }
    ]
  },
  "name": "sectionStyleMainArticleListQueue",
  "selections": [
    {
      "alias": "items",
      "args": [
        {
          "kind": "Literal",
          "name": "exclude",
          "value": {
            "types": "VIDEO"
          }
        }
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "__sectionStyleMainArticleListQueue__items_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "magazinesStyleLeadContentItemContent"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "magazinesStyleCardContentItemContent"
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__sectionStyleMainArticleListQueue__items_connection(exclude:{\"types\":\"VIDEO\"})"
    }
  ],
  "type": "Queue",
  "abstractKey": null
};

(node as any).hash = "3994cff431b8cad9143cf31dd287b436";

export default node;
