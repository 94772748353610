import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontPlayfairDisplay, theme } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

export const Cover = styled.div`
  position: relative;

  inline-size: 100%;
  block-size: 100%;

  overflow: hidden;
`;

export const Backdrop = styled.div`
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;

  inline-size: 100%;
  block-size: 100%;

  background: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1));

  opacity: 0.8;
`;

export const Content = styled.div`
  position: absolute;
  inset-block-end: 0;

  display: grid;
  grid-template:
    "." 1fr
    "section" min-content
    "title" min-content
    "divider" min-content
    "more" min-content;

  inline-size: 100%;
  block-size: 100%;
  padding-block: 24px 24px;
  padding-inline: 20px 20px;

  color: #ffffff;
  text-align: center;

  ${theme.breakpoints.up("tablet")} {
    padding-block: 25px 25px;
    padding-inline: 37px 37px;
  }
`;

export const Section = styled.div`
  grid-area: section;

  font-weight: 500;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 16.8px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    font-size: 16px;
    line-height: 19.2px;
  }
`;

export const Title = styled(EntityLink)`
  grid-area: title;

  display: -webkit-box;

  inline-size: 100%;
  margin-block: 14px 16px;

  color: #ffffff;
  font-weight: 400;
  font-size: 22px;
  font-family: ${fontPlayfairDisplay};
  line-height: 26px;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-tap-highlight-color: transparent;

  /* Fit clickable area to parent */
  &::before {
    position: absolute;
    inset-block: 0 0;
    inset-inline: 0 0;
    content: "";

    display: block;
  }

  ${theme.breakpoints.up("tablet")} {
    font-size: 26px;
    line-height: 34px;
  }
`;

export const Divider = styled.div`
  grid-area: divider;

  display: inline-block;

  inline-size: 76px;
  margin-block: 14px 0;
  margin-inline: auto;
  padding-block: 14px 0;

  border-block-start: 1px solid #f6f6f6;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 87px;
  }
`;

export const ReadMore = styled.div`
  grid-area: more;

  padding-block: 11.5px 11.5px;
  padding-inline: 8px 8px;

  font-weight: 500;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 16.8px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    padding-block: 12px 12px;
  }
`;

export const Container = styled.div`
  overflow: hidden;
`;
