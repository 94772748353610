/**
 * @generated SignedSource<<b21c5366fbc9e3eb22d824dbb317e226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionStyleContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleMainQuery">;
  readonly " $fragmentType": "sectionStyleContentQuery";
};
export type sectionStyleContentQuery$key = {
  readonly " $data"?: sectionStyleContentQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionStyleContentQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionStyleContentQuery",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "articlesQueueName",
          "variableName": "articlesQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "sectionStyleMainQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d5a32fa41abf3b3336421f23a8502716";

export default node;
